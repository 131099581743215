import React from "react";
import { Box } from "theme-ui";
import {
  Layout,
  SEO,
  Hero,
  About,
  Cta,
  Testimonials,
  Podcasts,
  ContactForm
} from "components";

let $, lity;
if (typeof window !== `undefined`) {
  $ = require("jquery");
  lity = require("lity");
}

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Jay Harvey" />
      <Hero></Hero>
      <About></About>
      <Cta></Cta>
      <Testimonials></Testimonials>

      <ContactForm></ContactForm>
    </Layout>
  );
};

export default IndexPage;
